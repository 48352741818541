<template>
  <div class="screen-type">
    <div class="title">
      选择类型
    </div>
    <div class="box">
      <div :class="['item', {'activate': selected === item.ID}]" @click="selectedType(item)" v-for="(item, index) in list" :key="index">
        {{ item.RankingName }}
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/api/config'

export default {
  name: "ScreenType",
  data () {
    return {
      selected: '',
      list: []
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    // 选择类型
    selectedType (item) {
      this.$emit('change', item)
      this.selected = item.ID
    },
    // 获取初始数据
    initialize () {
      API.School.SchoolRankingType().then(res => {
        this.list = res.data
        this.selected = res.data[0].ID

        this.$emit('change', res.data[0])
      })
    }
  }
}
</script>

<style scoped lang="less">
.screen-type {
  padding: .6rem;
  margin-bottom: 40px;
  border: 1px solid var(--color-grey);
  border-radius: .5rem;
  background-color: #fefefe;
  .title {
    padding-left: .3rem;
    font-size: var(--text-font-size);
    color: rgba(var(--themecolor),1);
  }
  .box {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
    .item {
      width: 200px;
      padding: .5rem 1rem;
      margin: 10px 20px 10px 0;
      text-align: center;
      border: 1px solid var(--color-grey);
      border-radius: .3rem;
      cursor: pointer;
      font-size: var(--text-font-size);
    }
    .activate {
      border:  1px solid rgba(var(--themecolor),0.75);
      color: rgba(var(--themecolor),1);
    }
  }
}

</style>
