<template>
  <div class="app-container university-rank">
    <div class="left">
      <screen-head></screen-head>
    </div>
    <div class="right">
      <div class="top">
<!--        <img class="top-img" src="https://jzy-test.obs.cn-north-4.myhuaweicloud.com/0cb2bee20bcb42b7be81f14fd9fb2e5d.jpg" alt="">-->
      </div>
      <div class="bottom">
        <screen-type @change="selectedType"></screen-type>

        <el-table
          :data="mainData.Data"
          width="100%"
          border
          :header-cell-style="centerClass"
          :cell-style="centerClass"
        >
          <el-table-column prop="RankingOfInternal" label="排名" align="center"
          ></el-table-column>
          <el-table-column label="学校logo">
            <template slot-scope="scope">
              <img class="bottom-logo" :src="scope.row.SchoolLogo" alt="">
            </template>
          </el-table-column>
          <el-table-column label="学校名称">
            <template slot-scope="scope">
              <span class="bottom-school-name" @click="openSchoolDetail(scope.row)">{{scope.row.SchoolName}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="SchoolCategoryNames" label="类型">
            <template slot-scope="prop">
              {{ prop.row.SchoolCategoryNameList[0] }}
            </template>
          </el-table-column>
          <el-table-column prop="ProvinceName" label="所在地"></el-table-column>
        </el-table>
        <custom-pagination
          layout="sizes, jumper"
          :pageIndex="query.PageIndex"
          :pageSize="query.PageSize"
          :pageSizeList="query.sizes"
          :total="mainData.Total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        ></custom-pagination>
      </div>
    </div>
  </div>
</template>

<script>
  import API from '@/api/config'
  // import { SchoolRanking, SchoolRankingType } from '@/api/college_details'
  import ScreenHead from './local-components/ScreenHead'
  import ScreenType from './local-components/ScreenType'
  export default {
    name: "university-rank",
    components: {
      ScreenHead,
      ScreenType
    },
    data () {
      return {
        mainData: {},
        query: {
          RankingTypeId: '',
          ProvinceId: '',
          PageIndex: 1,
          PageSize: 10,
          sizes: [10, 20, 50, 100, 300],
        }
      }
    },
    mounted () {
      this.initialize()
    },
    methods: {
      //打开学校详情
      openSchoolDetail(row){
        let routerUrl = this.$router.resolve({
          path: '/university-detail',
          query:{
            id: row.SchoolId
          }
        })
        window.open(routerUrl.href)
      },
      handleCurrentChange (currentPage) {
        this.query.PageIndex = currentPage
        this.getRankList()
      },
      handleSizeChange (pageSize) {
        this.query.PageSize = pageSize
        this.query.PageIndex = 1
        this.getRankList()
      },
      // 初始化数据
      initialize () {
        this.getRankList()
        this.screenRankList()
      },
      // 选择类型排名
      selectedType (params) {
        this.query.RankingTypeId = params.ID
        this.query.PageIndex = 1
        this.query.PageSize = 10
        this.getRankList()
      },
      //通过省份筛选排名
      screenRankList () {
        this.$globalEventBus.$on('universityRankScreen', data => {
          this.query.ProvinceId = data.ID
          this.query.PageIndex = 1
          this.query.PageSize = 10
          this.getRankList()
        })
      },
      //获取学校排名列表
      getRankList () {
        if (!this.query.RankingTypeId) return
        API.School.SchoolRanking({
          RankingTypeId: this.query.RankingTypeId || null,
          ProvinceId: this.query.ProvinceId || null,
          PageIndex: this.query.PageIndex,
          PageSize: this.query.PageSize,
        }).then(res => {
          this.mainData = res.data
        })
      },
      //table show center
      centerClass () {
        return 'text-align: center'
      },
    }
  }
</script>

<style scoped lang="less">
  .university-rank {
    display: flex;
    .left {
      margin-right: .5rem;
    }
    .right {
      .top {
        &-img {
          width: 100%;
          height: auto;
        }
      }
      .bottom {
        &-logo {
          width: 3rem;
          height: 3rem;
        }
        &-school-name{
          cursor: pointer;
          &:hover{
            color: rgba(var(--themecolor),1);
          }
        }
      }
    }
  }
</style>
