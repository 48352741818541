<template>
    <div class="screen-head">
      <div class="title">选择省份:</div>
      <ul class="content">
        <li class="content-item" v-for="(item, index) in mainData" :key="index">
          <div class="inner" :class="selected === index&&'selected'" @click="selectProvince(item,index)">
            {{item.Name}}
          </div>
        </li>
      </ul>
    </div>
</template>

<script>
  import API from '@/api/config'
  // import { BaseCondition } from '@/api/common'
    export default {
        name: "ScreenHead",
      data(){
          return{
            mainData: {},
            selected: 0,
          }
      },
      mounted(){
        this.initialize()
      },
      methods:{
          //选择省份
        selectProvince(item, index){
          this.selected = index
          this.$globalEventBus.$emit('universityRankScreen', item)
        },
          //初始化数据
        initialize() {
          API.School.BaseCondition().then(res =>{
            res.data.ProvinceList.unshift({
              Name: '全部',
              ID: ''
            })
            this.mainData = res.data.ProvinceList
          })
        }
      }
    }
</script>

<style scoped lang="less">
  .screen-head{
    width: 18rem;
    border: 1px solid var(--color-grey);
    padding: .6rem;
    border-radius: .5rem;
    .title{
      padding-left: .3rem;
      font-size: var(--text-font-size);
      color: rgba(var(--themecolor),1);
    }
    .content{
      display: flex;
      flex-wrap: wrap;
      margin-top: 1rem;

      &-item{
        width: 25%;
        padding: .3rem .2rem;
        .inner{
          padding: .5rem .2rem;
          text-align: center;
          border: 1px solid var(--color-grey);
          border-radius: .3rem;
          cursor: pointer;
          font-size: var(--text-font-size);
        }
        .selected{
          border:  1px solid rgba(var(--themecolor),0.75);
          color: rgba(var(--themecolor),1);
        }
      }
    }
  }
</style>
